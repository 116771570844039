import React from 'react'
import styled from 'styled-components'
import { navigate } from "gatsby"
import { Link } from "gatsby"
import EventLight from '../components/EventLight'
//  limit: 5 
// https://www.youtube.com/watch?v=T4IxIzhUUUs 
// How to build a Lightbox in Gatsby (w/ Gatsby Images & React Bootstrap)
// BE CAREFUL - MAI 2022
// Unfortunately simple-react-lightbox seems to no longer be maintained....
// not only is it no longer maintained, but they took down the whole repo.  hooray for open-source!
// https://react-bootstrap.github.io/layout/grid/
// https://getbootstrap.com/docs/4.0/utilities/spacing/
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"

const GalleryLight = () => {

  const data = useStaticQuery(graphql`
  query galleryLight {
    allFile(
      filter: {
      relativeDirectory: {eq: "themes"}}
      sort: {fields: base, order: ASC}) {
      edges {
        node {
          id
          publicURL
          base
          childImageSharp {
            gatsbyImageData(
              height: 900
              width: 1100
              transformOptions: {fit: COVER}
              placeholder: BLURRED
              webpOptions: {quality: 50}
            )
          }
        }
      }
    }
  }
  `
  )
  return (
    <GalleryContainer>
        <TopLine>
            Galerie
        </TopLine>
            {/* Bootstrap works off a 12-column => 12/4=3*/}
            {/* Bootstrap utility classe "py-1" */}
            <Container>
                <SimpleReactLightbox>
                      <Row>
                        {data.allFile.edges.map(({node}) => (
                          <Col lg={3} md={6} key={node.id} className="py-3">
                          <Linked>
                            <Link to={`../${node.base.split('-').join(' ').split('.')[0]}/`}>{node.base.split('-').join(' ').split('.')[0]}
                              <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('-').join(' ').split('.')[0] } />
                              </Link>
                          </Linked>
                          </Col>
                        ))}
                      </Row>
                </SimpleReactLightbox>
                <Custom>
                <h3>Belle promenade sur mes toiles</h3>
                </Custom>
            </Container>
    </GalleryContainer>
  )
}

export default GalleryLight

const GalleryContainer = styled.div`
  min-height: 85vh;
  img {
    margin-top: 1rem;
  }
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  )
  `
  
const Linked = styled.p`
    text-align: center;
    a {
      color:white;
      font-size: 1.2rem;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;
  }
    a:hover {
      outline-width: 0;
      text-decoration: none;
      color: #ab9668;
    }
        @media screen and (max-width: 768px) {
          a {
            color: black;
        }   
    }
  `
const TopLine = styled.p`
  font-family: BetterGrade;  
  font-size: 10rem; 
  color : #404640;
    text-align: center;
    margin-bottom: 3rem;
      @media screen and (max-width: 768px) {
        margin-top: 3rem;
        padding: 0px;
        font-size: 5rem
      }
`
const Custom = styled.p`
text-align: center;
padding: 3rem;
    h3 {
      font-size: 1.2rem;
      color : #404640;
      color: white;
      }
        @media screen and (max-width: 768px) {
        }
  `
