import React from 'react'
import styled from 'styled-components'
import { FaInstagram } from 'react-icons/fa';

const Event = () => {
  return (
   <EventContainerFlex> 
   <h3>
   </h3>
     Suivez-moi
      <Icon>
      <a href="https://instagram.com/christine.grillet.10?igshid=YmMyMTA2M2Y=" rel="noreferrer noopener" target="_blank"> <FaInstagram /> </a>
      </Icon>
   </EventContainerFlex>
  )
}

export default Event

 {/* Un overlay sombre sur la video avec :before pour mettre en avant le texte  */}
const EventContainerFlex = styled.div `
  background:rgba(69, 73, 69, 0.636);
  text-align: center;
  width: auto;
  color: white;
  padding-top: 1rem ;
  font-size: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const Icon = styled.div`
    a{
      font-size: 60px;
      margin: 20px;
      color: black;
      &:hover { 
        color: black;
        color : #bc1f70 ;
      }
          @media screen and (max-width: 768px) {
            font-size: 45px;
          }
    }
`